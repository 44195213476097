import md5 from 'js-md5'
import dayjs from 'dayjs'
// 防抖
// export function debounce(func, wait = 0, immdeiate = false) {
//   let timerId
//   return function () {
//     var __this = this
//     var args = arguments
//     if (timerId) {
//       clearTimeout(timerId)
//     }
//     if (immdeiate && !timerId) {
//       func.apply(__this, args)
//     }
//     timerId = setTimeout(function () {
//       func.apply(__this, args)
//     }, wait)
//   }
// }
// 节流
export function throttleFirstExt(f, t) {
  let flag = true;
  return (...args) => {
    if (flag) {
      f(...args);
      flag = false;
      setTimeout(() => {
        flag = true;
      }, t);
    }
  };
}
// 生成签名sign
export function createSign(config) {
  // console.log(config)
  const paramsArr = [
    "x-aio-appid",
    "x-aio-trace-id",
    "client",
    "client-version",
    "source",
    "channel",
    "services-version",
    "time",
  ];
  let signParams = {};
  paramsArr.forEach((item) => {
    signParams[item.toLocaleLowerCase()] = config.headers[item] || "";
  });
  let signString = process.env.VUE_APP_SECRET_KEY;
  Object.keys(signParams)
    .sort()
    .forEach((key) => {
      let encodeVal = encodeURIComponent(signParams[key]);
      signString += `@${encodeVal}`;
    });
  signString += `@${process.env.VUE_APP_SECRET_KEY}`;
  signString = md5(signString).toUpperCase();
  return signString;
}

// host,data => host?data
export function createUrl(host, data) {
  let url = host;
  if (data) {
    url += "?";
    Object.keys(data).forEach((key) => {
      url += `${key}=${data[key]}&`;
    });
    url = url.substring(0, url.length - 1);
  }
  return url;
}

// 是否登录过 => user_info/false  token过期场景首次访问还是返回用户信息
export function isLogin() {
  return (
    JSON.parse(localStorage.getItem("clientCommonMessage") || "{}")
      ?.user_info || null
  );
}

// lodash 深拷贝
export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

// 下划线转驼峰
export function responseToCamelCase(data) {
  if (data instanceof Array) {
    return data.map((item) => {
      return responseToCamelCase(item);
    });
  } else if (data instanceof Object) {
    const result = {};
    Object.keys(data).forEach((key) => {
      const newKey = key.replace(/_(\w)/g, (all, letter) => {
        return letter.toUpperCase();
      });
      result[newKey] = responseToCamelCase(data[key]);
    });
    return result;
  } else {
    return data;
  }
}

// 驼峰转下划线
export function requestToSnakeCase(data) {
  if (data instanceof Array) {
    return data.map((item) => {
      return requestToSnakeCase(item);
    });
  } else if (data instanceof Object) {
    const result = {};
    Object.keys(data).forEach((key) => {
      const newKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
      result[newKey] = requestToSnakeCase(data[key]);
    });
    return result;
  } else {
    return data;
  }
}
export const getDayOfYear = (addDay = 730) => {
  const time = Date.parse(new Date()) / 1000;
  const addTime = addDay * 24 * 60 * 60;
  const tempDate = time + addTime;
  return dayjs.unix(tempDate || 0).format('YYYY-MM-DD');
};

export function updateQueryStringParameter(uri, key, value) {
	if(!value) {
		return uri;
	}
	var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
	var separator = uri.indexOf('?') !== -1 ? "&" : "?";
	if (uri.match(re)) {
		return uri.replace(re, '$1' + key + "=" + value + '$2');
	}
	else {
		return uri + separator + key + "=" + value;
	}
}
// setCookie
export const setCookie = (name, value, days, domain) => {
  var date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  var expires = "expires=" + date.toGMTString();
  var cookie = name + "=" + value + "; " + expires + "; path=/";
  if (domain) {
    cookie += "; domain=" + domain;
  }
  document.cookie = cookie;
}
// getCookie
export const getCookie = (name) => {
  const reg = RegExp(`${name}=([^;]+)`);
  const arr = document.cookie.match(reg);
  if (arr) {
    return arr[1];
  }
  return '';
}
// 返回公参sourceUrl
export const doSourceUrl = () => {
	const utmSource = localStorage.getItem('utm_source');
	const utmPlan = sessionStorage.getItem('utm_plan');
	const utmTerm = sessionStorage.getItem('utm_term');
	const utmKeywordid = sessionStorage.getItem('utm_keywordid');
	const utmKeyword = sessionStorage.getItem('utm_keyword');
	const bdVid = sessionStorage.getItem('bd_vid');
	let sourceUrl = window.location.href;
	if (utmSource) {
		sourceUrl = updateQueryStringParameter(sourceUrl, 'utm_source', utmSource);
	}
	if (utmPlan) {
		sourceUrl = updateQueryStringParameter(sourceUrl, 'utm_plan', utmPlan);
	}
	if (utmTerm) {
		sourceUrl = updateQueryStringParameter(sourceUrl, 'utm_term', utmTerm);
	}
	if (utmKeywordid) {
		sourceUrl = updateQueryStringParameter(sourceUrl, 'utm_keywordid', utmKeywordid);
	}
	if (utmKeyword) {
		sourceUrl = updateQueryStringParameter(sourceUrl, 'utm_keyword', decodeURIComponent(utmKeyword));
	}
	if (bdVid) {
		sourceUrl = updateQueryStringParameter(sourceUrl, 'bd_vid', bdVid);
	}
	return sourceUrl;
}

// 阿拉伯数字转汉字
export const numberToChinese = (number) => {
  const chineseNumberMap = {
    '0': '零',
    '1': '一',
    '2': '二',
    '3': '三',
    '4': '四',
    '5': '五',
    '6': '六',
    '7': '七',
    '8': '八',
    '9': '九'
  };


  let chinese = '';

  const digits = number.toString().split('').reverse();

  for (let i = 0; i < digits.length; i++) {
    const digit = digits[i];
    chinese = chineseNumberMap[digit] + chinese;
  }

  return chinese;
}

// 判断url地址是什么类型：1.图片 2.json 3.其他
export const judgeUrlType = url => {
  if (/\.(jpg|jpeg|png)$/.test(url)) {
      return 'img';
  }
  if (/\.(json)$/.test(url)) {
      return 'lottie';
  }
  return 'other';
};

// 获取当前时间和time是在同一天
export const isSameDay = time => {
  const currentTime = Date.parse(new Date()) / 1000;
  return dayjs.unix(currentTime).format('YYYY-MM-DD') === dayjs.unix(time).format('YYYY-MM-DD');
};
