export interface State {
  preBrowse: object | null;
}
export default {
  state: {
    preBrowse: null,
  },
  mutations: {
    setPreBrowseInfo(state: State, payload: any) {
      state.preBrowse = payload;
    },
  },
  actions: {},
};
