import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import '@/assets/css/pagination.less'
import '@/assets/css/base.less';
import '@/assets/fonts/SourceHanSerifCN-Bold.css';
import xesTrack from '@/trace/xesTrack';
import registerDirectives from "./directives/index.ts";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
xesTrack();
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
registerDirectives(app)
app.use(store).use(router).use(ElementPlus, {locale}).mount('#app')
