import request from '@/utils/request';

// 会员配置详情
export function getVipDetail(data :{[key: string]: any} = {}) {
    return request.post({
        url: 'basicsapi/v1/vip/detail',
        data,
    });
}

// 获取支付数据
export function getConfirmData(data :{[key: string]: any} = {}) {
    return request.post({
        url: 'shopapi/v2/goods/confirm',
        data,
    });
}

// 创建订单
export function doOrderCreate(data :{[key: string]: any} = {}) {
    return request.post({
        url: 'shopapi/v2/order/create',
        data,
    });
}

// 发起支付 - 获取收银台地址
export function getOrderPayUrl(data :{[key: string]: any} = {}) {
    return request.post({
        url: 'shopapi/v2/order/pay',
        data,
    });
}

// 支付状态查询
export function getNativePayStatus(data :{[key: string]: any} = {}) {
    return request.post({
        url: 'order/v1/pay/status',
        data,
    });
}