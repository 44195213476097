import { ActionContext } from 'vuex'
import UDC from '@/utils/passport';
import { ElMessage } from 'element-plus'
import { getCodeLogin } from '@/api/login'
import { deepClone, isLogin, setCookie } from '@/utils/utils'
import { ILoginState } from '@/interface/user'
import { statusLogin } from '@/trace/xesTackHome';

const originState = {
    showLogin: false,
    phone: '',
    smsCode: '',
    agreement: false,
    activeTab: 1,
    codeSend: false,
    codeLoading: false,
    codeError: false,
    transferCodeInfo: {
        transfer_code: '',
        expire_time: null,
        status: 0, // 0 加载中 1 已生成 2 已扫码 3 已授权 4 已失效 5 已刷新 
    },
    loginCallBack: []
}

export default {
    getters: {
        // 手机号是否合法
        isPhone(state: ILoginState) {
            return /^1[3456789]\d{9}$/.test(state.phone);
        },
        // 验证码是否合法
        isSmsCode(state: ILoginState) {
            return /^\d{6}$/.test(state.smsCode);
        },
        canLogin(state: ILoginState, getters: any) {
            return getters.isPhone && getters.isSmsCode;
        }
    },
    state: deepClone(originState),
    mutations: {
        resetState(state: ILoginState) {
            Object.assign(state, deepClone(originState));
        },
        showLogin(state: ILoginState, cb?: () => object) {
            state.showLogin = true;
            document.body.classList.add("modal-open");
            cb && state.loginCallBack.push(cb);
        },
        hideLogin(state: ILoginState) {
            state.showLogin = false;
            document.body.classList.remove("modal-open");
            state.loginCallBack = [];
        },
        changeTab(state: ILoginState, payload: number) {
            state.activeTab = payload;
        },
        changeCodeError(state: ILoginState, payload: boolean) {
            state.codeError = payload;
        },
        changeCodeSend(state: ILoginState, payload: boolean) {
            state.codeSend = payload;
        },
        changeCodeLoading(state: ILoginState, payload: boolean) {
            state.codeLoading = payload;
        },
        writeTransferInfo(state: ILoginState, payload: any) {
            Object.assign(state.transferCodeInfo, payload);
        },
    },
    actions: {
        openLogin({ commit }: ActionContext<ILoginState, any>, payload?: () => object) {
            if(isLogin()) {
                window.location.reload();
                return;
            }
            commit('showLogin', payload);
        },
        closeLogin({ state, commit, dispatch }: ActionContext<ILoginState, any>) {
            commit('hideLogin');
            if (state.activeTab === 2) {
                dispatch('cancelQrcode', '#QRcodeBox')
            }
            commit('resetState');
        },
        getCode({ state, commit }: ActionContext<ILoginState, any>) {
            commit('changeCodeLoading', true);
            UDC.getSMSCode({ phone: state.phone })
                .then(() => {
                    ElMessage.success('验证码已发送');
                    commit('changeCodeSend', true);
                })
                .catch(e => {
                    ElMessage.error(e.errmsg || '网络连接失败，请检查网络连接情况');
                }).finally(() => {
                    commit('changeCodeLoading', false);
                });
        },
        async codeLogin({ state, commit, dispatch }: ActionContext<ILoginState, any>, code: string) {
            try {
                const res: any = await getCodeLogin({
                    tal_code: code,
                    client_id: '601101',
                })
                ElMessage.success('登录成功');
                const services_version =  window.location.hostname.includes('xesvip.cn') ? 28 : 29
                const clientCommonMessage = {
                    tal_id: res.data?.userInfo?.tal_id,
                    token: res.data?.bok_token,
                    user_id: res.data?.userInfo?.userid,
                    user_info: res.data?.userInfo,
                    services_version
                };
                localStorage.setItem('clientCommonMessage', JSON.stringify(clientCommonMessage));
                setCookie('tal_token', res.data.tal_token, 30, 'xesvip.cn')
                // 有缓存函数走缓存函数
                if(state.loginCallBack.length) {
                    state.loginCallBack.forEach((cb) => {
                        cb({ clientCommonMessage });
                    })
                    commit('hideLogin');
                    return
                }
                statusLogin({
                    type: '登录成功',
                    is_regist: res.data?.userInfo?.is_register === 1 ? 1 : 0,
                    stu_id: res.data?.userInfo?.userid
                });
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            } catch (error) {
                statusLogin({
                    type: '登录失败',
                    is_regist: 0,
                });
                console.log(error, 'error')
                ElMessage.error('登录失败');
            } finally {
                commit('changeCodeLoading', false);
            }
        },
        login({ state, commit, dispatch }: ActionContext<ILoginState, any> ) {
            return new Promise((resolve, reject) => {
                commit('changeCodeLoading', true);
                UDC.login({ phone: state.phone, sms_code: state.smsCode })
                    .then(async (result: any) => {
                        dispatch('codeLogin', result.data.code)
                    })
                    .catch(e => {
                        console.log(e, 'login,error')
                        commit('changeCodeLoading', false);
                        reject(e)
                    });
            })
        },
        getTransferCode({ commit, dispatch }: ActionContext<ILoginState, any>, scene: number) {
            UDC.getTransferCode({ scene: 1 })
                .then((result: any) => {
                    console.log(result)
                    commit('writeTransferInfo', result.data);
                    dispatch('getTransferCodeStatus');
                })
        },
        getTransferCodeStatus({ state, commit, dispatch }: ActionContext<ILoginState, any>) {
            let statusSuccess = false;
            let makeCode = false;
            const timer = setInterval(() => {
                UDC.getTransferCodeStatus({ scene: 1, transfer_code: state.transferCodeInfo.transfer_code })
                    .then((res: any) => {
                        console.log(res, '轮训')
                        if (res.data.status == 1) {
                            console.log('码已生成')
                            !makeCode && commit('writeTransferInfo', { status: +res.data.status });
                            makeCode = true;
                        } else if (res.data.status == 2) {
                            console.log('码已扫')
                            !statusSuccess && commit('writeTransferInfo', { status: +res.data.status });
                            statusSuccess = true;
                        } else if (res.data.status == 3) {
                            clearTimeout(timer);
                            console.log('码已授权')
                            // 拿code换取token进行登录
                            dispatch('codeLogin', res.data.code)
                        } else if (res.data.status == 4) {
                            console.log('码已失效')
                            commit('writeTransferInfo', { status: +res.data.status });
                            clearTimeout(timer);
                        }
                    })
            }, 1000);
        },
        cancelQrcode({ dispatch }: ActionContext<ILoginState, any>, el: string) {
            UDC.cancelQrcode();
            const QRcodeBox = document.querySelector(el);
            // 清空html
            QRcodeBox && QRcodeBox.innerHTML && (QRcodeBox.innerHTML = '');
        },
        loginQrcode({ dispatch }: ActionContext<ILoginState, any>, el: string) {
            UDC.loginQrcode({ renderToEle: el })
                .then((result: any) => {
                    dispatch('codeLogin', result.data.code);
                })
                .catch(e => {
                    console.log(e, 'loginQrcode-err')
                })
        },
        loginOut({ commit }: ActionContext<ILoginState, any>, cb?: any) {
            UDC.loginOut(() => {
                if(cb) {
                    return cb()
                }
                ElMessage.success('退出成功')
                statusLogin({
                    type: '退出登录',
                    is_regist: 0,
                });
                setTimeout(() => {
                    localStorage.clear();
                    window.location.reload();
                }, 1000)
            })
        }
    },
};

