import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { NEED_LOGIN_ROUTERS } from '@/const/utils';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../views/paper/homePage.vue')

  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/paper',
    name: 'paper',
    component: () => import(/* webpackChunkName: "paper" */ '../views/paper/homePage.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/paper/homePage.vue')
  },
  {
    path: '/single-paper',
    name: '/singlePaper',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/singlePaper/singlePaper.vue')
  },
  {
    path: '/browserHistory',
    name: 'browserHistory',
    component: () => import(/* webpackChunkName: "browserHistory" */ '../views/paper/BrowserHistory.vue')
  },
  {
    path: '/myPaper',
    name: 'myPaper',
    component: () => import(/* webpackChunkName: "myPaper" */ '../views/paper/MyPaper.vue')
  }, 
  {
    path : "/agreement",
    name: "agreement",
    component: () => import('@/views/agreement/agreement.vue')
  },
  {
    path: '/:pathMatch(.*)',
    //访问主页的时候 重定向到index页面
    redirect: '/404',
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "noFound" */ '../views/NotFound.vue')

  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  document.body.scrollTop = 0; 
  document.documentElement.scrollTop = 0; 
  window.pageYOffset = 0;
  if (to.query?.utm_source) {
    const utmSource = (to.query.utm_source as string)?.indexOf('pc') === 0 ? to.query.utm_source as string : `pc${to.query.utm_source}`
    localStorage.setItem('utm_source', utmSource)
  }
  if (to.query?.utm_plan) {
    sessionStorage.setItem('utm_plan', to.query?.utm_plan as string)
  }
  if (to.query?.utm_term) {
    sessionStorage.setItem('utm_term', to.query?.utm_term as string)
  }
  if (to.query?.utm_keywordid) {
    sessionStorage.setItem('utm_keywordid', to.query?.utm_keywordid as string)
  }
  if (to.query?.utm_keyword) {
    sessionStorage.setItem('utm_keyword', to.query?.utm_keyword as string)
  }
  if (to.query?.bd_vid) {
    sessionStorage.setItem('bd_vid', to.query?.bd_vid as string)
  }
  if (NEED_LOGIN_ROUTERS.includes(to.path) && !(localStorage.getItem('clientCommonMessage') && JSON.parse(localStorage.getItem('clientCommonMessage') || '{}')?.token)) {
    next({
      path: '/paper',
    })
    return
  }
  next();
})

export default router
