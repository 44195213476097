export enum PaperViewFrom {
    SEARCHLIST = 1,
    MINE = 2,
}
export enum OriginFrom {
    'gaokao.com' = '高考网',
    'zhongkao.com' = "中考网",
    'aoshu.com' = "奥数网",
    'zuowen.com' = "作文网"
}
export enum VISIT_CHANNEL {
    'pcgaokao' = '高考网',
    'pczhongkao' = "中考网",
    'pcaoshu' = "奥数网",
    'pczuowen' = "作文网",
    'pcbaidu' = "百度"
}
export enum  Departion {
     '小学' = 1,
     "初中" = 2,
     "高中" = 3
}

export enum FiltersName {
    province = '省',
    grade = '年级',
    semester = '学期',
    difficulty = '难度',
    school_type = '名校',
    classify = '类型',
    year = '年份'
}