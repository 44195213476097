import { xesTrackClick, xesTrackPv, xesTrackShow, xesTrackStatus } from '@/trace/xesTrack';

export const pctestpaper = {
  show: function (value: object = {}) {
    xesTrackShow('show_pctestpaper_vip_openvip', {
      page_name: 'pctestpaper',
      module_name: 'vip',
      button_name: 'openvip',
      event_name: 'PC试卷宝首页会员模块立即开通曝光',
      ...value,
    });
  },
  clickOpenvip: function (value: object = {}) {
    xesTrackClick('click_pctestpaper_vip_openvip', {
      page_name: 'pctestpaper',
      module_name: 'vip',
      button_name: 'openvip',
      event_name: '点击PC试卷宝首页会员模块立即开通',
      ...value,
    });
  }
}

export const pcbrohistory = {
  show: function (value: object = {}) {
    xesTrackShow('show_pcbrohistory_vip_openvip', {
      page_name: 'pcbrohistory',
      module_name: 'vip',
      button_name: 'openvip',
      event_name: 'PC浏览记录页会员模块立即开通曝光',
      ...value,
    });
  },
  clickOpenvip: function (value: object = {}) {
    xesTrackClick('click_pcbrohistory_vip_openvip', {
      page_name: 'pcbrohistory',
      module_name: 'vip',
      button_name: 'openvip',
      event_name: '点击PC浏览记录页会员模块立即开通',
      ...value,
    });
  }
}

export const pcpaper = {
  show: function (value: object = {}) {
    xesTrackShow('show_pcpaper_vip_openvip', {
      page_name: 'pcpaper',
      module_name: 'vip',
      button_name: 'openvip',
      event_name: 'PC试卷宝会员模块立即开通曝光',
      ...value,
    });
  },
  clickOpenvip: function (value: object = {}) {
    xesTrackClick('click_pcpaper_vip_openvip', {
      page_name: 'pcpaper',
      module_name: 'vip',
      button_name: 'openvip',
      event_name: '点击PC试卷宝会员模块立即开通按钮',
      ...value,
    });
  }
}

export const showPcpaperPopupvipbuyBlank = function (value: object = {}) {
  xesTrackShow('show_pcpaper_popupvipbuy_blank', {
    page_name: 'pcpaper',
    module_name: 'popupvipbuy',
    event_name: '曝光试卷宝购买弹窗',
    ...value,
  });
};
export const clickPcpaperPopupvipbuyTop = function (value: object = {}) {
  xesTrackClick('click_pcpaper_popupvipbuy_top', {
    page_name: 'pcpaper',
    module_name: 'popupvipbuy',
    button_name: 'top',
    event_name: '点击名字', // 点击注册/登录
    ...value,
  });
};
export const showPcpaperPopupvipbuySku = function (value: object = {}) {
  xesTrackShow('show_pcpaper_popupvipbuy_sku', {
    page_name: 'pcpaper',
    module_name: 'popupvipbuy',
    button_name: 'sku',
    event_name: '曝光商品卡片',
    ...value,
  });
}
export const clickPcpaperPopupvipbuyBlank = function (value: object = {}) {
  xesTrackClick('click_pcpaper_popupvipbuy_blank', {
    page_name: 'pcpaper',
    module_name: 'popupvipbuy',
    button_name: 'sku',
    event_name: '点击商品卡片',
    ...value,
  });
}
export const clickPcpaperPopupvipbuyBuy = function (value: object = {}) {
  xesTrackClick('click_pcpaper_popupvipbuy_buy', {
    page_name: 'pcpaper',
    module_name: 'popupvipbuy',
    button_name: 'buy',
    event_name: '点击立即购买按钮',
    ...value,
  });
}
export const clickPcpaperPopupvipbuyAgreement = function (value: object = {}) {
  xesTrackClick('click_pcpaper_popupvipbuy_agreement', {
    page_name: 'pcpaper',
    module_name: 'popupvipbuy',
    button_name: 'agreement',
    event_name: '点击智能学+服务协议',
    card_type: '试卷宝',
    vip_version: '基础版/通用版',
    ...value,
  });
}
export const showPcpaperPopuppaysuccessBlank = function (value: object = {}) {
  xesTrackShow('show_pcpaper_popuppaysuccess_blank', {
    page_name: 'pcpaper',
    module_name: 'popuppaysuccess',
    event_name: '曝光试卷宝支付成功弹窗',
    card_type: '试卷宝',
    vip_version: '基础版/通用版',
    ...value,
  });
}
export const clickPcpaperPopuppaysuccessBlank = function (value: object = {}) {
  xesTrackClick('click_pcpaper_popuppaysuccess_blank', {
    page_name: 'pcpaper',
    module_name: 'popuppaysuccess',
    button_name: 'touse',
    event_name: '点击试卷宝购买弹窗',
    ...value,
  });
}
export const showPcpaperPopupaskBlank = function (value: object = {}) {
  xesTrackShow('show_pcpaper_popupask_blank', {
    page_name: 'pcpaper',
    module_name: 'popupask',
    event_name: '曝光试卷宝询问弹窗',
    ...value,
  });
}
export const clickPcpaperPopupaskBlank = function (value: object = {}) {
  xesTrackClick('click_pcpaper_popupask_blank', {
    page_name: 'pcpaper',
    module_name: 'popupask',
    event_name: '点击试卷宝询问弹窗',
    ...value,
  });
}
export const pcpaperOrderPaidSuccess = function (value: object = {}) {
  xesTrackShow('pcpaper_order_paid_success', {
    event_name: '支付成功',
    ...value,
  });
}
export const pcpaperOrderPaid = function (value: object = {}) {
  xesTrackShow('pcpaper_order_paid', {
    event_name: '订单生成',
    ...value,
  });
}

