// interval: 间隔时间 ms
// time: 剩余时间 ms
import { ref, computed } from 'vue'

interface IuseNow {
  interval?: number
  time: number,
  onFinish?: () => void
}
export type CurrentTime = {
  days: number;
  hours: number;
  total: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}
const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR

export function parseTime(time: number): CurrentTime {
  const days = Math.floor(time / DAY);
  const hours = Math.floor((time % DAY) / HOUR);
  const minutes = Math.round((time % HOUR) / MINUTE);
  const seconds = Math.round((time % MINUTE) / SECOND);
  const milliseconds = Math.floor(time % SECOND);

  return {
    total: time,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
}

const useCountDown = (option: IuseNow = { interval: 1000, time:5000 }) => {
  let isStop = false
  let timerID: number | null = null
  const remain = ref(option.time)
  const current = computed(() => parseTime(remain.value))
  
  const start = () => {
    const endTime:number = isStop ? Date.now().valueOf() + remain.value : Date.now().valueOf() + option.time
    if (isStop) {
      isStop = false
    }
    if (!timerID) {
      timerID = setInterval(() => {
        if (isStop) return
        remain.value = endTime - Date.now().valueOf()
        // console.log(current.value.seconds)
        if (remain.value <= 0) {
          reset()
          option.onFinish && option.onFinish()
        }
      }, option.interval)
    }
  }

  const pause = () => {
    if (timerID) {
      clearInterval(timerID)
      timerID = null
      isStop = true
    }
  }

  const reset = (totalTime:number = option.time) => {
    if (timerID) {
      clearInterval(timerID)
    }
    remain.value = totalTime
    timerID = null
    isStop = true
  }

  return {
    current,
    start,
    reset,
    pause
  }
}

export default useCountDown
