import { xesTrackClick, xesTrackPv, xesTrackShow, xesTrackStatus } from '@/trace/xesTrack';

export const clickPay = function (value: object = {}) {
  xesTrackClick('click_pcpaperdetail_paypopup_pay', {
    page_name: 'pcpaperdetail',
    module_name: 'paypopup',
    button_name: 'pay',
    event_name: '购买弹窗点击支付',
    ...value,
  });
};

export const showPcpaperdetailPaypopupBlank = function (value: object = {}) {
  xesTrackShow('show_pcpaperdetail_paypopup_blank', {
    page_name: 'pcpaperdetail',
    module_name: 'paypopup',
    event_name: '试卷宝购买弹窗曝光',
    ...value,
  });
}
