import instance, { McAxiosRequestConfig } from "./axios";
import { AxiosInstance, AxiosResponse } from "axios";

// type RequestMethod = 'get' | 'post'
class Request {
    private instance: AxiosInstance

    constructor(instance: AxiosInstance) {
        this.instance = instance
    }

    public get(params: {
        url: string,
        data : {[key: string]: any},
        config?:  McAxiosRequestConfig
    }): Promise<AxiosResponse<any>> {
        const { url = '', data = {}, config = {} as McAxiosRequestConfig } = params
        return this.request('get', url, config, {params: data})
    }

    public post(params: {
        url: string,
        data : {[key: string]: any},
        config?:  McAxiosRequestConfig
    }): Promise<AxiosResponse<any>> {
        const { url = '', data = {}, config = {} as McAxiosRequestConfig } = params
        return this.request('post', url, config, data)
    }

    private async request(
        method: string,
        url: string,
        config: McAxiosRequestConfig,
        ...params: any
    ) {
        if(method === 'get') {
            return await this.instance.get(url, ...params, config)
        } else {
            return await this.instance.post(url, ...params, config)
        }
    }
}
export default new Request(instance)
