import { doSourceUrl } from '@/utils/utils';
export const calculateCouponsList = (promotionCoupons = []) => {
	const finalPromotionCouponList = []
	if (promotionCoupons && promotionCoupons.length > 0) {
        promotionCoupons.forEach(promotion => {
            // 初始化最终领取的优惠券信息
            finalPromotionCouponList.push({
                couponId: promotion?.coupons[0]?.uuid,
                promotionId: promotion?.promotionId || '',
                group: promotion?.coupons[0]?.group || 5, // 优惠券促销分组
            });
        });
    }
	return finalPromotionCouponList || [];
}
// 创建订单数据
export const getGoodsList = (list = [], dealCoupon, promotionCoupons) => {
	let skus = [];
	let promotion = [];
	let cartIds = [];

	let pick = list => {
		let l = [];

		list.forEach(
			({
				cartInfo,
				skuId,
				skuUnionid,
				spuId,
				spuUnionid,
				skuPicture,
				skuSpec,
				spuName,
				productType,
				salePrice,
				sourceId,
				sourceUrl,
			}) => {
				// if ([1, 3].includes(cartInfo.cartGoodsStatus)) {
				cartIds.push(cartInfo.id);

				l.push({
					skuId,
					skuUnionid,
					productCount: cartInfo?.goodsNum,
					spuId,
					spuUnionid,
					cartId: cartInfo?.id,
					productImage: skuPicture[0],
					skuSpec: (skuSpec || []).map(
						({specValueName}) => specValueName
					),
					productName: spuName,
					productType,
					saleSingleFee: salePrice,
					sourceId,
					sourceUrl,
				});
				// }
			}
		);

		return l;
	};

	for (let i = 0; i < list.length; i++) {
		skus = [...skus, ...pick(list[i]?.skuList)];
		if (list[i]?.promotionId > 0) {
			promotion = [
				...promotion,
				{
					promotionId: list[i]?.promotionId,
					gifts: pick(list[i]?.usedGifts),
				},
			];
		}
		for (let k = 0; k < list[i].includePromotionList?.length; k++) {
			skus = [...skus, ...pick(list[i].includePromotionList[k]?.skuList)];
			if (list[i].includePromotionList[k]?.promotionId) {
				promotion = [
					...promotion,
					{
						promotionId:
							list[i].includePromotionList[k]?.promotionId,
						gifts: pick(list[i].includePromotionList[k]?.usedGifts),
						group: list[i].includePromotionList[k]?.promotionGroup || 0,
					},
				];
			}
		}
	}

	// 处理优惠券信息
	if (dealCoupon) {
		const finalPromotionCouponList = calculateCouponsList(promotionCoupons);
		finalPromotionCouponList && finalPromotionCouponList?.forEach(final => {
			if (final && final.couponId) {
				promotion.push(JSON.parse(JSON.stringify(final)));
			}
		});
	}

	return [skus, promotion, cartIds];
};
// 获取订单数据params
export const rePlayLoad = (item) => {
    const {skuId, promotion} = item;
    const giftPromotion = promotion?.filter(e => e.promotionGroup === 2 || e.promotionGroup === 3);
    const promotionId = giftPromotion?.[0]?.promotionId || 0;
    const couponPromotions = promotion?.reduce((acc, item) => {
        if (item.promotionGroup === 5) {
            acc.ids.push(item.promotionId);
            acc.codes.push(item.promotionCode);
        }
        return acc;
    }, { ids: [], codes: [] }) || []; 
    const playLoad = {
        sku_info: [{
            sku_unionid: skuId,
            goods_num: 1,
            promotion_id: promotionId,
        }],
        coupon_promotionids: couponPromotions?.ids || [],
        coupon_promotion_codes: couponPromotions?.codes || [],
        include_prom: 0, // 是否包含促销 0 默认包含 1显式包含 2不包含 (服务端老师沟通后传0)
        type: 2, // 备注：结算来源 0购物车 1商详页立即结算 2智能学+结算
        source_id: '',
        source_url: doSourceUrl(),
        
    }
    return playLoad;
}

// 权益购买成功提示文案
export const getSuccessTip = (data) => {
	const resPayData = data?.map(item => JSON.parse(item.response))
	const showMsg = resPayData?.reduce((acc, item) => {
        return [...acc, ...item.show_msg.split('\n')];
    }, []);
    // 是否有多行文案 1:单行 2:多行
    const showType = showMsg?.length > 1 ? 2 : 1;
    // 是否包含试卷 1: 包含 0:不包含
    const hasPaper = resPayData?.filter(el => el.has_paper).length > 0 ? 1 : 0;
	// 该权益是否包含一年卡auth_day=365（一年卡有赠品，通过一年卡判断是否展示赠品二维码）
	const hasOneYearCard = resPayData?.filter(el => el.auth_day === 365).length > 0 ? true : false;
    return{
		msgs: showMsg,
		msgType: showType,
		hasPaper,
		hasOneYearCard,
	} || {}
}

// 埋点数据
export const xesTrackVipPackageInfo = (data) => {
	return {
		sku_unionid: data?.skuId || '',
		sku_name: data?.skuTitle || '',
		promotion_id: data?.promotion?.map(e => e?.promotionId).join(',') || '',
		card_type: '试卷宝',
		vip_version: '基础版/通用版',
		payscene: '普通支付',
	}
}