import store from '@/store/index';
import { isLogin } from '@/utils/utils'
export interface State {
    showVipDialog: boolean;
    position: string;
    entrance: string;
}
export default {
    state: {
        showVipDialog: false,
        position: '',
        entrance: '',
    },
    mutations: {
        showVipDialog(state: State, payload = {
            position: '',
            entrance: '',
        }) {
            state.showVipDialog = true;
            state.position = payload?.position || '';
            state.entrance = payload?.entrance || '';
            document.body.classList.add("modal-open");
        },
        hideVipDialog(state: State) {
            state.showVipDialog = false;
            document.body.classList.remove("modal-open");
        }
    },
    actions: {
        openVipDialog({ commit, dispatch }: any, payload = {}) {
            if(!isLogin()){
                dispatch('user/resetUserInfo')
            }
            commit('showVipDialog', payload);
        },
        closeVipDialog({ commit }: any) {
            commit('hideVipDialog');
            store.commit("setPreBrowseInfo", null);
        }
    },
};
  