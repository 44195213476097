import { ActionContext } from 'vuex'
import { getUserInfo, getUserServiceStatus } from '@/api/user'
import { ServiceStatusType, ServiceType } from '@/const/user'
import { IUserState } from '@/interface/user'
import { isLogin } from '@/utils/utils'

export default {
  namespaced: true,
  getters: {
    // 试卷宝会员状态
    paperVipInfo(state: IUserState) {
      const service: any = state.userService?.filter((item) => item.service_type === ServiceType['试卷宝']);
      return service.length ? Object.assign(service[0], { name: ServiceStatusType[service?.use_status] }) : null;
    }
  },
  state: {
    userInfo: isLogin(),
    userService: [],
    network: true,
    requestDone: {
      getUserInfo: false,
      getUserServiceStatus: false,
    },
  },
  mutations: {
    setUserInfo(state: IUserState, payload: any) {
      state.userInfo = payload;
    },
    setServices(state: IUserState, payload: any) {
      state.userService = payload;
    },
    setNetwork(state: IUserState, payload: any) {
      state.network = payload;
    },
    addRequestDone(state: IUserState, payload: any) {
      state.requestDone[payload.name] = payload.value;
    }
  },
  actions: {
    init({ dispatch }: ActionContext<IUserState, any>) {
      dispatch('getUserInfo');
      dispatch('getUserServiceStatus', { type: 0 });
      dispatch('userPing');
    },
    async getUserInfo({ state, commit }: ActionContext<IUserState, any>) {
      try {
        const res = await getUserInfo()
        commit('setUserInfo', res.data?.userInfo || null);
        // 同步更新clientCommonMessage
        const clientCommonMessage = JSON.parse(localStorage.getItem('clientCommonMessage') || '{}');
        if (clientCommonMessage.user_info) {
          clientCommonMessage.user_info = res.data?.userInfo || null;
          localStorage.setItem('clientCommonMessage', JSON.stringify(clientCommonMessage));
        }
      } catch (error) {
        console.log(error, 'getUserInfo')
      } finally {
        commit('addRequestDone', {
          name: 'getUserInfo',
          value: true,
        });
      }
    },
    async getUserServiceStatus({ state, commit }: ActionContext<IUserState, any>) {
      try {
        const res = await getUserServiceStatus()
        commit('setServices', res.data?.list || []);
      } catch (error) {
        console.log(error, 'getUserServiceStatus')
      } finally {
        commit('addRequestDone', {
          name: 'getUserServiceStatus',
          value: true,
        });
      }
    },
    userPing({ state, commit }: ActionContext<IUserState, any>) {
      console.log('互联网连接状态', navigator.onLine);
      window.addEventListener('online', () => {
        console.log('设备已连接到互联网');
        commit('setNetwork', true);
      });
      
      window.addEventListener('offline', () => {
        console.log('设备未连接到互联网');
        commit('setNetwork', false);
      });
    },
    resetUserInfo({ commit }: ActionContext<IUserState, any>) {
      commit('setUserInfo', null);
      commit('setServices', []);
    }
  }
};

