import request from '@/utils/request';

export function getUserServiceStatus(data :{[key: string]: any} = {}) {
  return request.get({
      url: 'basicapi/v1/user/user-service-status',
      data,
  });
}

export function getUserInfo(data :{[key: string]: any} = {}) {
  return request.post({
      url: 'v1/user/profile/info',
      data,
  });
}