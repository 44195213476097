import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { createSign } from './utils'
import { v4 as uuidv4 } from 'uuid'
import { ElMessage } from 'element-plus'
import { isLogin } from '@/utils/utils'
import { NEED_LOGIN_ROUTERS } from '@/const/utils';

type IHeaders = AxiosRequestHeaders & {
  'Content-Type': string,
  'x-aio-appid': string,
  client: string,
  source: number,
  channel: number,
}
export type  McAxiosRequestConfig = AxiosRequestConfig & {
  // extraConfig?: {
  //   tokenRetryCount: number
  // },
  headers: IHeaders
}
axios.defaults.timeout = 15000
const config: McAxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'x-aio-appid': process.env.VUE_APP_ID,
    client: 'web',
    source: 4,
    channel: 1
  } as IHeaders
}
const instance = axios.create(config)

instance.interceptors.request.use(async (cfg) => {
  const config = cfg as McAxiosRequestConfig
  //服务版本，兼容老域名，中盘28，默认1 
  const hostname = window.location.hostname
  const default_services_version = hostname.includes('xesvip.cn') ? 28 : 29
  const services_version = localStorage.getItem('service_version') || default_services_version
  const {
    token = '',
    user_id = '',
    service_version_name = '1.10.1',
    service_version = services_version
  } = JSON.parse(localStorage.getItem('clientCommonMessage') || '{}')
  config.headers['token'] = token
  config.headers['client-version'] = `v${service_version_name}`
  config.headers['services-version'] = service_version
  config.headers['uid'] = user_id
  // 请求时更新这个值
  config.headers['time'] = Date.parse(new Date().toString()) / 1000
  // 将uuidv4中的-去掉，不使用replaceAll
  config.headers['x-aio-trace-id'] = uuidv4().replace(/-/g, '')
  // config.headers['x-aio-trace-id'] = uuidv4().replaceAll('-', '')


  // 接口签名
  const sign = createSign(config)
  config.headers['sign'] = sign
  // if (!config?.extraConfig?.tokenRetryCount) {
  //   Object.assign(config.headers, {
  //     extraConfig:  {
  //       tokenRetryCount: 0
  //     }
  //   })
  // }
  config.url = process.env.VUE_APP_API_URL + config.url
  return config
})

instance.interceptors.response.use(
  (response) => {
    // 100101 token失效
    if (response?.data?.code === 100101) {
      const { host, pathname, protocol } = window.location
      if (pathname && NEED_LOGIN_ROUTERS.includes(pathname)) {
        window.location.href = `${protocol}//${host}`
      }
      // 已登录过，token失效，提示1次
      isLogin() && ElMessage.info('登录失效，请重新登录');
      localStorage.removeItem('clientCommonMessage');
      return Promise.reject(response.data)
    }
    if(response?.data?.code !== 0) {
      if(response?.data?.code === 100){
        ElMessage.error('数据错误，请求失败');
      }else{
        ElMessage.error(response?.data?.message || '请求失败');
      }
      return Promise.reject(response.data)
    }
    return response.data
  },
  async (err) => {
    if (axios.isCancel(err)) {
      //取消请求，不报错
      return
    }
    if (err.message === 'Network Error') {
      console.log(err, '网络不佳，请稍后再试')
      return Promise.reject(err.message)
    }
    if (err.message.includes('timeout')) {
      console.log(err, '请求超时，请稍后再试')
      return Promise.reject(err.message)
    }
    if (err.response?.status >= 500) {
      return
    }
    const { data: responseData } = err.response || {}
    const { status } = responseData || {}
    // if (status) {
    //   switch (parseInt(status)) {
    //     case 401:
    //       break;
    //     default:
    //       break;
    //   }
    // }
    // if (err.response.status !== 200) {
    //   Promise.reject(err.response)
    // }
    return Promise.reject(err.response)
  }
)

export default instance
