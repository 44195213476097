import { xesTrackClick, xesTrackPv, xesTrackShow, xesTrackStatus } from '@/trace/xesTrack';

const page_name = 'pctestpaper';

export const pvPctestpaperBlankBlank = function (value: object = {}) {
  xesTrackPv('pv_pctestpaper_blank_blank', {
    page_name,
    event_name: 'PC试卷宝首页浏览',
    ...value,
  });
};

// 曝光试卷宝弹窗
export const showPctestpaperPopupBlank = function (value: object = {}) {
  xesTrackShow('show_pctestpaper_popup_blank', {
    page_name,
    event_name: '曝光试卷宝弹窗',
    module_name: 'popup',
    ...value,
  });
};

// 点击弹窗了解更多按钮
export const clickPctestpaperPopupSee = function (value: object = {}) {
  xesTrackClick('click_pctestpaper_popup_see', {
    page_name,
    event_name: '点击弹窗了解更多按钮',
    button_name:'see',
    module_name: 'popup',
    ...value,
  });
};

// 关闭弹窗
export const clickPctestpaperPopupClose = function (value: object = {}) {
  xesTrackClick('click_pctestpaper_popup_close', {
    page_name,
    event_name: '关闭弹窗',
    button_name:'close',
    module_name: 'popup',
    ...value,
  });
};

// PC试卷宝首页营销位曝光 show_pctestpaper_market_blank
export const showPctestpaperMarketBlank = function (value: object = {}) {
  xesTrackShow('show_pctestpaper_market_blank', {
    page_name,
    event_name: 'PC试卷宝首页营销位曝光',
    module_name: 'market',
    ...value,
  });
}

// 点击PC试卷宝首页营销位 click_pctestpaper_market_blank
export const clickPctestpaperMarketBlank = function (value: object = {}) {
  xesTrackClick('click_pctestpaper_market_blank', {
    page_name,
    event_name: '点击PC试卷宝首页营销位',
    module_name: 'market',
    ...value,
  });
}

// 点击PC试卷宝首页筛选项 click_pctestpaper_screen_blank
export const clickPctestpaperScreenBlank = function (value: object = {}) {
  xesTrackClick('click_pctestpaper_screen_blank', {
    page_name,
    event_name: '点击PC试卷宝首页筛选项',
    module_name: 'screen',
    ...value,
  });
}

// 点击PC试卷宝首页试卷流 click_pctestpaper_stream_paper
export const clickPctestpaperStreamPaper = function (value: object = {}) {
  xesTrackClick('click_pctestpaper_stream_paper', {
    page_name,
    event_name: '点击PC试卷宝首页试卷流',
    module_name: 'stream',
    button_name: 'paper',
    ...value,
  });
}

// 	点击试卷宝首页本地名校试卷 click_pctestpaper_eliteschool_paper
export const clickPctestpaperEliteschoolPaper = function (value: object = {}) {
  xesTrackClick('click_pctestpaper_eliteschool_paper', {
    page_name,
    event_name: '点击试卷宝首页本地名校试卷',
    module_name: 'eliteschool',
    button_name: 'paper',
    ...value,
  });
}
// 登录
export const statusLogin = function (value: object = {}) {
  xesTrackStatus('status_pcpaper_framework_login', {
    page_name: 'pcpaper',
    event_name: '登录状态',
    button_name:'login',
    module_name: 'framework',
    ...value,
  });
};
