import { saInit, saRegisterPage, saTrack } from '@xes/dh-sensor';
import { VISIT_CHANNEL } from '@/const/paper';
import { doSourceUrl } from '@/utils/utils';

const CATEGARAY = 'mall';
const SENSORS = process.env.VUE_APP_SENSORS

saInit({
  server_url: SENSORS,
  pro_name: 'pro_all_in_one',
  show_log: false,
  user_id: 0,
  open_fmp: false,
  heart_beat_mode: false,
});
const getVisitChannel: any = function () {
  const channel = window.localStorage.getItem('utm_source') || '';
  if (!channel) {
    return {};
  }
  return {
    visit_channel: VISIT_CHANNEL[channel as keyof typeof VISIT_CHANNEL] || '',
  };
}
export default function () {
  const {tal_id, user_id, services_version} = JSON.parse(localStorage.getItem("clientCommonMessage") || "{}");
  // 设置公参
  saRegisterPage({
    pro_name: 'pro_all_in_one',
    biz_name: CATEGARAY,
    tal_id,
    stu_id: user_id,
    version: services_version
  });
}

const handleAssignObj = () => {
  return {
      source_id: '',
      source_url: doSourceUrl(),
  }
};

export const xesTrackClick = function (name: string, value = {}, callback?: () => object) {
  console.log({ event_type: 'click', ...value, ...handleAssignObj(), ...getVisitChannel(), event: name}, '===埋点===click');
  saTrack(name, { event_type: 'click', ...value, ...handleAssignObj(),  ...getVisitChannel() as object}, callback);
};
export const xesTrackPv = function (name: string, value = {}, callback?: () => object) {
  console.log({event_type: 'pv', ...value, ...handleAssignObj(), ...getVisitChannel(),  event: name}, '===埋点===pv');
  saTrack(name, { event_type: 'pv', ...value, ...handleAssignObj(), ...getVisitChannel() }, callback);
};
export const xesTrackShow = function (name: string, value: object = {}, callback?: () => object) {
  console.log({event_type: 'show', ...value, ...handleAssignObj(), ...getVisitChannel(),  event: name}, '===埋点==show');
  saTrack(name, { event_type: 'show', ...value, ...handleAssignObj(), ...getVisitChannel() }, callback);
};

export const xesTrackStatus = function (name: string, value = {}, callback?: () => object) {
  console.log({event_type: 'status', ...value, ...handleAssignObj(), ...getVisitChannel(), event: name}, '===埋点==status');
  saTrack(name, { event_type: 'status', ...value, ...handleAssignObj(), ...getVisitChannel() }, callback);
};
