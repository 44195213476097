// xesTrackFramework
import { xesTrackClick, xesTrackPv, xesTrackShow, xesTrackStatus } from '@/trace/xesTrack';
const page_name = 'pcpaper';

export const showPcpaperFrameworkLoginfloat = function (value: object = {}) {
    xesTrackShow('show_pcpaper_framework_loginfloat', {
      page_name,
      module_name: 'framework',
      button_name: 'loginfloat',
      event_name: '用户登陆浮层曝光',
      ...value,
    });
  };

export const showPcpaperFrameworkLoginEntrance = function (value: object = {}) {
    xesTrackShow('show_pcpaper_framework_login', {
      page_name,
      module_name: 'framework',
      button_name: 'login',
      event_name: '登陆/注册入口曝光',
      ...value,
    });
  };
export const clickPcpaperFrameworkLoginEntrance = function (value: object = {}) {
  xesTrackClick('click_pcpaper_framework_login', {
      page_name,
      module_name: 'framework',
      button_name: 'login',
      event_name: '登陆/注册入口点击',
      ...value,
    });
  };

export const clickPcpaperFrameworkRenewBtn = function (value: object = {}) {
  xesTrackClick('click_pcpaper_framework_renew', {
      page_name,
      module_name: 'framework',
      button_name: 'renew',
      event_name: '登陆浮层续费按钮点击',
      ...value,
    });
};
// 顶部会员btn点击事件
export const clickPcpaperFrameworkVipBtn = function (value: object = {}) {
  xesTrackClick('click_pcpaper_framework_vip', {
      page_name,
      module_name: 'framework',
      button_name: 'vip',
      event_name: '点击PC试卷宝框架会员按钮',
      ...value,
    });
};
// 顶部栏曝光
export const showPcpaperFrameworkBlank = function (value: object = {}) {
  xesTrackShow('show_pcpaper_framework_blank', {
    page_name,
    module_name: 'framework',
    event_name: 'PC试卷宝顶部框架曝光',
    ...value,
  });
};
