export enum ServiceStatusType {
  '未开通' = 0,
  '已开通' = 1,
  '已过期' = 2,
  '未登录' = 9
}

export enum ServiceType {
  '解题宝' = 1,
  '试卷宝' = 2
}