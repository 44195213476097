/**
 * UDC 登录
 */
import TAL_UC from '@udc/tal-passport-jssdk';
import '@udc/tal-passport-jssdk/lib/qrcode.css';
import store from '@/store';
import { ElMessage } from 'element-plus'

const isProd = process.env.VUE_APP_PREPATH === 'production';

function commonSuccessHandle({
    methodName = '',
    option = {},
    response = {},
    resolve,
    reject,
}: any) {
    const captcha = document.querySelector('#captcha');

    if (response.errcode === 0) {
        captcha && (captcha.innerHTML = '');
        resolve(response);
    }
    else if (response.errcode === 11021) {
        // showToast('滑块验证通过后，会自动触发');
        // 需要展示滑块
        const param = {
            // 滑动验证通过时执行方法名，（即触发滑块的原方法，例如：登录（loginSms），
            // 获取验证码（sendSmsCodeLogin），校验旧手机（checkPhone））
            slideCallback: methodName,
            // 前端滑动验证通过时执行方法名的参数（例如手机号，验证码,success和fail等等）
            slideParams: option,
            // 方法名出错
            failCallback(error: any) {
                // errmsg 错误字段
                console.log(error, 'error')
                reject(error);
            },
        };
        // 已存在滑块提示
        if((captcha as Element).childNodes.length) {
            ElMessage.info('请点击登录按钮上方的滑块完成校验')
        }
        // 展示滑块
        TAL_UC.showSlide(param);
        // 关闭loading
        store.commit('changeCodeLoading', false);
        // 关闭验证码文字提示
        store.commit('changeCodeError', false);
    }
    else {
        console.log(response, '滑块error')
        captcha && (captcha.innerHTML = '');
        reject(response);
    }
}

class UDC {
    constructor() {
        TAL_UC.rankConfig({
            'client-id': '601101', // client-id由平台发放权限码，接入之前向平台申请
            'env': isProd ? 'production' : 'sandbox', // 环境标识： 'sandbox': 沙箱环境； 'production': 线上生产环境
            'loginout_callback': function (res: any) { // 未登录/登录失效统一处理回调方法
                console.warn(res, '=== 未登录/登录失效统一处理回调方法 =======');
            },
        });
        TAL_UC.configNVC({
            renderToEle: '#captcha',
            isH5: true,
        });
    }

    getSMSCode({ phone, phone_code = '86', send_type = 1 }: any) {
        return new Promise((resolve, reject) => {
            const params = {
                data: {
                    send_type,
                    phone_code,
                    phone,
                },
                success: function (response: any) {
                    commonSuccessHandle({
                        methodName: 'sendSMSCodeLogin',
                        option: params,
                        response,
                        resolve,
                        reject,
                    });
                },
                fail: function (error: any) {
                    reject(error);
                },
            };

            TAL_UC.sendSMSCodeLogin(params);
        });
    }

    login({ phone, sms_code, phone_code = '86' }: any) {
        return new Promise((resolve, reject) => {
            const params = {
                data: { phone, phone_code, sms_code },
                success: function (response: any) {
                    commonSuccessHandle({
                        methodName: 'loginSms',
                        option: params,
                        response,
                        resolve,
                        reject,
                    });
                },
                fail: function (error: any) {
                    reject(error);
                },
            };
            TAL_UC.loginSms(params);
        });
    }

    getTransferCode({ scene }: { scene: number }) {
        return new Promise((resolve, reject) => {
            const params = {
                data: { scene },
                success: function (response: any) {
                    commonSuccessHandle({
                        methodName: 'getTransferCode',
                        option: params,
                        response,
                        resolve,
                        reject,
                    });
                },
                fail: function (error: any) {
                    reject(error);
                },
            };
            TAL_UC.getTransferCode(params);
        });
    }
    getTransferCodeStatus({ scene, transfer_code }: { scene: number, transfer_code: string }) {
        return new Promise((resolve, reject) => {
            const params = {
                data: { scene, transfer_code },
                success: function (response: any) {
                    commonSuccessHandle({
                        methodName: 'getTransferCodeStatus',
                        option: params,
                        response,
                        resolve,
                        reject,
                    });
                },
                fail: function (error: any) {
                    reject(error);
                },
            };
            TAL_UC.getTransferCodeStatus(params);
        });
    }

    cancelQrcode(){
        TAL_UC.cancelQrcode()
    }

    loginQrcode({ renderToEle }: { renderToEle: string }) {
        return new Promise((resolve, reject) => {
            const params = {
                renderToEle,
                successCallback: function (response: any) {
                    commonSuccessHandle({
                        methodName: 'loginQrcode',
                        option: params,
                        response,
                        resolve,
                        reject,
                    });
                },
                failCallback: function (error: any) {
                    reject(error);
                },
            };
            TAL_UC.loginQrcode(params);
        });
    }

    loginOut(successCallback?: any, failCallback?: any) {
        const params = {
            data: {},
            success: function () {
                successCallback && successCallback();
            },
            fail: function (error: any) {
                failCallback && failCallback(error);
            }
        }

        TAL_UC.loginOut(params)
    }
}

export default new UDC();
