import { createStore } from 'vuex'
import login from './login.ts'
import user from './user.ts'
import paperPreBrowse from './paperPreBrowse.ts'
import vip from './vip.ts'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    login, user,paperPreBrowse, vip
  }
})
